import { render, staticRenderFns } from "./addMore-modal.vue?vue&type=template&id=0582d401&"
import script from "./addMore-modal.vue?vue&type=script&lang=js&"
export * from "./addMore-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports